import {
	idCardInit
} from '@/api/index.js';

export default {
	data() {
		return {

		}
	},
	created() {
		if (this.tag === 'sms') { // 通过短信链接访问
			this.getCardInfo();
		}
	},
	methods: {
		// 获取状态数据(通过短信链接进入该页时)
		getCardInfo() {
			
			idCardInit(this.unionid).then(res => {
				let {checkState} = res.data;
		
				// 判断审核状态: 0-待审(进入待审页面 - 当前页面); 1-审核通过(进入登记成功页面); 2-审核失败(进入实名校验页面)
				if (checkState == 0) {
					// this.$router.replace(this.lang === 'en' ? '/waiting_en' : '/waiting'); // 当前页面, 无需跳转
				} else if (checkState == 1) {
					this.$router.replace(this.lang === 'en' ? '/memberCode_en' : '/memberCode');
				} else if (checkState == 2) {
					this.$router.replace(this.lang === 'en' ? '/realnameAuth_en' : '/realnameAuth');
				}
				
			})
		
		},
	}
}
